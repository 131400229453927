import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { Button } from '@mui/material';
import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';

const StatusContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  
`;

const StyledPaper = styled(Paper)(({
  theme,
}) => `
  position: relative;
  box-sizing: border-box;
  padding: ${theme.spacing(4)}; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10%;
  text-align: center;
  letter-spacing: 0.5px;
  width: fit-content;
  max-width: 90vw;
  @media (max-width: 600px) {
    height: 40%;
  }
`);
interface StatusInfoProps extends PropsWithChildren<unknown> {
  title: string;
}
const StatusInfoCard = ({
  title,
  children,
}: StatusInfoProps) => {
  const handleClick = useCallback(() => {
    window.history.back();
  }, []);

  return <StatusContainer>
    <StyledPaper>
      <Typography
        component={'span'}
        variant="h1">{ title }</Typography>
      <p
        style={{
          lineHeight: '1.5rem',
          maxWidth: '80%',
          wordWrap: 'break-word',
          whiteSpace: 'revert',
        }}
      >{children}</p>
      <Button onClick={handleClick}>Go back</Button>
    </StyledPaper>
  </StatusContainer>;
};

export default StatusInfoCard;
