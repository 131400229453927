/* eslint-disable @typescript-eslint/no-explicit-any */
import { MemberStatus } from '@mindhiveoy/schema';
import { useEffect, useState } from 'react';
import type { PropsWithChildren, ReactElement } from 'react';

import { AuthContext, useAuth } from '@mindhiveoy/react-auth';
import { MembershipContext } from './useMembership';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { firebaseApp } from 'schema';
import RoleManager from 'utils/firebase/roleManager';
import type { Member, ShortUserRoleId } from '@shared/schema/src';
import type { UserContext, UserRoleContextDescriptor } from '@mindhiveoy/auth';
import type { WithRenderWhenDenied } from '@mindhiveoy/react-auth';

export interface MembershipProviderProps extends WithRenderWhenDenied {

  /**
   * If true, the user must be authenticated before entering into space
   */
  requiresAuthentication?: boolean;

  /**
   * If true, user may enter the component content only with active membership
   */
  requiresMembership?: boolean;
  /**
   * Element rendered when membership is pending. The default value is null.
   */
  onPending?: ReactElement | null;
  /**
   * Element rendered when membership is closed. The default value is null.
   */
  onClosed?: ReactElement | null;
  /**
   * Element rendered when member has been invited but not accepted the invitation. The default value is null.
   */
  onInvited?: ReactElement | null;
  /**
   * Descriptor for platform user role contexts.
   */
  contextDesc: UserRoleContextDescriptor<any, any>;
  /**
   * User's current role context, which is in use
   */
  userContext: UserContext;
}

/**
 * A component to define an user role and access on within a specific user role context.
 *
 * @param MembershipProviderProps
 */
export const MembershipProvider = ({
  requiresAuthentication,
  requiresMembership,
  children = null,
  orRender = null,
  onPending = null,
  onClosed = null,
  onInvited = null,
  contextDesc: roleContext,
  userContext,
}: PropsWithChildren<MembershipProviderProps>): JSX.Element | null => {
  const currentAuth = useAuth<any, any, any, any, any>();

  const {
    user,
    authenticated,
    acquireAuthentication,
  } = currentAuth;

  const [auth, setAuth,] = useState(currentAuth);

  const [membership, setMembership,] = useState<Member<ShortUserRoleId>>();

  useEffect(() => RoleManager
    .getInstance(roleContext)
    .subscribe(user, userContext, (membership) => {
      setAuth(membership ? {
        ...currentAuth,
        ...userContext.params,
        contextId: userContext.contextId,
        memberStatus: membership.status as MemberStatus,
        memberId: membership.id,
        role: membership.role,
      } as any : {
        ...currentAuth,
        ...userContext.params,
        contextId: userContext.contextId,
      } as any);
    }), [currentAuth, roleContext, user, userContext,]);

  useEffect(() => {
    if (!auth.authenticated) {
      setMembership(undefined);
      return;
    }
    const resolveMemberRef = () => {
      const firestore = getFirestore(firebaseApp());
      switch (userContext.contextId) {
        case 'space':
          return doc(firestore, `spaces/${userContext.params.spaceId}/members/${auth.memberId}`);
        case 'project':
          return doc(firestore, `spaces/${userContext.params.spaceId}/projects/${userContext.params.projectId}/projectMembers/${auth.memberId}`);
        default:
          throw new Error(`Unexpected contextId: ${userContext.contextId}`);
      }
    };

    const memberRef = resolveMemberRef();

    return onSnapshot(memberRef, (doc) => {
      setMembership(doc.exists() ?
        doc.data() as Member<ShortUserRoleId> :
        undefined);
    });
  }, [auth?.authenticated, auth?.memberId, userContext,]);

  if (requiresAuthentication && !authenticated) {
    acquireAuthentication();
    return orRender;
  }

  if (requiresMembership) {
    if (!authenticated) {
      acquireAuthentication();
      return orRender;
    }
    if (!auth.memberId) {
      return orRender;
    }
    switch (auth.memberStatus) {
      case MemberStatus.PENDING:
        return onPending;
      case MemberStatus.CLOSED:
        return onClosed;
      case MemberStatus.INVITED:
        return onInvited;
      default:
        throw new Error(`Unexpected member status: ${auth.memberStatus}`);
    }
  }
  return (
    <MembershipContext.Provider value={membership}>
      <AuthContext.Provider value={auth}>
        {children}
      </AuthContext.Provider>
    </MembershipContext.Provider>
  );
};
