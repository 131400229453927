import { cardsVariants } from '../../utils/variants';
import { motion } from 'framer-motion';
import React from 'react';
import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';

const CardContainer = styled(motion.div)(({
  theme,
}) => `
  max-width: 1400px;
  width: 100%;
  padding: ${theme.spacing(2)};
`);

const Cards = ({
  children,
}: PropsWithChildren<unknown>) =>
  <CardContainer
    key="cards"
    layout
    variants={cardsVariants}
    initial="hidden"
    animate="visible"
    exit="exit"
  >
    {children}
  </CardContainer>;

export default Cards;
