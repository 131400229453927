import { Typography } from '@mui/material';
import DefaultScreenLayout from 'components/layout/DefaultScreenLayout';
import React from 'react';
import SignIn from 'components/auth/SignIn';
import type { PropsWithChildren } from 'react';

const ErrorScreen = ({
  children,
}: PropsWithChildren<any>) => {
  return <DefaultScreenLayout>
    {children}
  </DefaultScreenLayout>;
};
interface ErrorInfoProps {
  error: any;
}
export const ErrorInfo = ({
  error,
}: ErrorInfoProps) => {
  const code = error?.code;
  switch (code) {
    case 'permission-denied':
      return <ErrorScreen>
        <SignIn />
      </ErrorScreen>;
    case 'not-found':
      return <ErrorScreen>
        <Typography variant="h4">
          Not found
        </Typography>
      </ErrorScreen>;
    default:
      return <ErrorScreen>
        {error.message}
      </ErrorScreen>;
  }
};
