import styled from '@emotion/styled';

const CardItem = styled.div(({
  theme,
}) => `
  width: 100%;
  min-height: 240px;
  z-index: 250;
  cursor: pointer;
  position: relative;
  border-radius: ${theme.shape.borderRadius}px;
  overflow: hidden;
`);

export default CardItem;
