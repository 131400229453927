import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'next-i18next';
import BackgroundFade from 'components/styling/BackgroundFade';
import CardItem from './CardItem';
import LiveIndicator from 'components/canvas/components/LiveIndicator';
import MediaContainer from './MediaContainer';
import React from 'react';
import styled from '@emotion/styled';
import type { CSSProperties } from 'react';
import type { Media } from '@shared/schema/src';

export const LeftForMediaCard = styled.div`
  display: flex;
  flex: 3;
  flex-direction: row;
  align-items: flex-end;
  max-width: 75%;
`;

export const RightForMediaCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const StyledActionsForMediaCard = styled(CardActions)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  height: 100px;
`;

export const DescriptionAreaForMediaCard = styled(CardActions)(({
  theme,
}) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  border-radius: ${theme.shape.borderRadius}px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: ${theme.spacing(1)};
  height: 14rem;
`);

export const TitleForMediaCard = styled(Typography)`
  color: white;
  font-size: 200%;
  text-align: left;
  z-index: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`;

export interface MediaCardProps {
  title?: string;
  description?: string;
  elements?: JSX.Element[];
  disabled?: boolean;
  media?: Media;
  addCard?: boolean;
  live?: boolean;
  onClick?: React.MouseEventHandler<unknown>;
}

const liveIndicatorStyle: CSSProperties = {
  position: 'absolute',
  display: 'flex',
  flexDirection: 'row',
  left: 0,
  top: 0,
};

const MediaCard = ({
  title,
  elements,
  media,
  live,
}: MediaCardProps): JSX.Element => {
  const {
    t,
  } = useTranslation();

  return <CardItem>
    <MediaContainer media={media}>
      <BackgroundFade>
        {
          live && <div style={liveIndicatorStyle} >
            <LiveIndicator />
            {t('Live')}
          </div>
        }
        <DescriptionAreaForMediaCard>
        </DescriptionAreaForMediaCard>
        <StyledActionsForMediaCard>
          <LeftForMediaCard>
            <TitleForMediaCard gutterBottom
              variant="h2" >
              {title}
            </TitleForMediaCard>
          </LeftForMediaCard>
          <RightForMediaCard>
            {elements}
          </RightForMediaCard>
        </StyledActionsForMediaCard>
      </BackgroundFade>
    </MediaContainer>
  </CardItem>;
};

export default MediaCard;
