import { createContext, useContext, useEffect, useState } from 'react';
import { useSpace } from 'bloc/space/SpaceBloc';
import type { CurrentContextProviderProps } from './CurrentContextProviderProps';
import type { Space } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

interface CurrentSpaceContextType {
  /**
   * The current space
   */
  space: WithId<Space> | undefined;
  /**
   * True, when the space is loading
   */
  loading: boolean;
}

const CurrentSpaceContext = createContext<CurrentSpaceContextType | null>(null);

export const CurrentSpaceProvider: React.FC<CurrentContextProviderProps> = ({
  children,
  pathParams,
}) => {
  const spaceBloc = useSpace(pathParams);

  const [space, setSpace,] = useState<WithId<Space>>();

  const [loading, setLoading,] = useState(true);

  useEffect(() => spaceBloc.subscribe(
    (space) => {
      setSpace(space);
      setLoading(!space);
    },
    (error) => {
      console.error('Error getting space', error);
      setLoading(false);
    }
  ), [spaceBloc,]);

  return (
    <CurrentSpaceContext.Provider value={{
      space,
      loading,
    }}>
      {children}
    </CurrentSpaceContext.Provider>
  );
};

/**
 * Use the current space in the url path
 * @returns   The current space
 */
export const useCurrentSpace = () => {
  const context = useContext(CurrentSpaceContext);
  if (!context) {
    throw new Error('useCurrentSpace must be used within a CurrentSpaceProvider');
  }
  return context;
};
