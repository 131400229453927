import { createContext, useContext, useEffect, useState } from 'react';
import { useSession } from 'bloc/session/SessionBloc';
import type { CurrentContextProviderProps } from './CurrentContextProviderProps';
import type { Session } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

interface CurrentSessionContextType {
  /**
   * The current session
   */
  session: WithId<Session> | undefined;
  /**
   * True, when the session is loading
   */
  loading: boolean;

  mode?: 'draft' | 'published';
}

const CurrentSessionContext = createContext<CurrentSessionContextType | null>(null);

export const CurrentSessionProvider: React.FC<CurrentContextProviderProps> = ({
  pathParams,
  children,
}) => {
  const sessionBloc = useSession(pathParams);

  const [session, setSession,] = useState<WithId<Session>>();

  const [loading, setLoading,] = useState(true);

  useEffect(() => sessionBloc.subscribe(
    (session) => {
      setSession(session);
      setLoading(!session);
    },
    (error) => {
      console.error('Error getting session', error);
      setLoading(false);
    }
  ), [sessionBloc,]);

  return (
    <CurrentSessionContext.Provider value={{
      session,
      loading,
    }}>
      {children}
    </CurrentSessionContext.Provider>
  );
};

/**
 * Use the current session in the url path
 * @returns   The current session
 */
export const useCurrentSession = () => {
  const context = useContext(CurrentSessionContext);
  if (!context) {
    throw new Error('useCurrentSession must be used within a CurrentSessionProvider');
  }
  return context;
};
