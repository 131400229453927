import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import styled$ from 'utils/react/styled$';

/**
 * @param {boolean} $withTopMargin 16px top margin
 * @param {boolean} $withBottomMargin 16px bottom margin
 * @return {JSX.Element} General smoky background without the form and
with smaller background pixelation. Use on the background any element to create an
element group or make text readable
 */
const SmokyBackground = styled$(motion.div)<{
  $withTopMargin?: boolean;
  $withBottomMargin?: boolean;
}>(({
  $withTopMargin,
  $withBottomMargin,
  theme,
}) => css`
  position: relative;
  align-items: center;
  display: flex;
  border-radius: ${theme.shape.borderRadius}px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: ${theme.spacing(2)};
  min-width: min(100%, ${theme.shape.maxEditorAreaWidth}px, calc(100vw - ${theme.spacing(2)}));
  height: 100%;
  width: 100%;
  background-color: ${theme.palette.background.paper60};
  backdrop-filter: blur(4px);
  // z-index: -1;
  margin-top: ${$withTopMargin ? theme.spacing(2) : 0};
  margin-bottom: ${$withBottomMargin ? theme.spacing(2) : 0};
`);

export default SmokyBackground;
