/* eslint-disable indent */
import { keyframes } from '@emotion/react';
import { useTranslation } from 'next-i18next';
import styled from '@emotion/styled';

export const LivePulseSpeed = 1.5;

export const LivePulseColor = 'rgba(15,255,15,0.7)';

export const livePulseAnimationKeyFrames = keyframes`
  0% {
    transform: scale(0.9);
    opacity: 0.7;
  }
  70% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.7;
  }
`;

export const livePulseFrameAnimationKeyFrames = keyframes`
  0% {
    border-color: ${LivePulseColor};
  }
  70% {
    border-color: rgba(128,255,128,1);
  }
  100% {
    border-color: ${LivePulseColor};
  }
`;

const AbsoluteLiveIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  background-color: ${LivePulseColor};
  border-radius: 50%;
  animation: ${livePulseAnimationKeyFrames} ${LivePulseSpeed}s infinite;
  margin: 8px;
  z-index: 100000;
`;

const RelativeLiveIndicator = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  background-color: rgba(15,255,15,0.7);
  border-radius: 50%;
  animation: ${livePulseAnimationKeyFrames} ${LivePulseSpeed}s infinite;
  margin: 0px 8px;
  padding-bottom: 4px;
`;

const LiveSpan = styled.span`
  color: ${LivePulseColor};
  padding-bottom: 2px;
`;

const LiveInfoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  flew-wrap: nowrap;
`;

export const LiveInfo = () => {
  const {
    t,
  } = useTranslation();

  return <LiveInfoContainer>
    <LiveSpan>{t('Live')}</LiveSpan>
    <LiveIndicator />
  </LiveInfoContainer>;
};
export interface LiveIndicatorProps {
  absolute?: boolean;
}

/**
 * Live Session Indicator Component
 *
 * @param {LiveIndicatorProps} props
 */
const LiveIndicator = ({
  absolute = false,
}: LiveIndicatorProps) => absolute ?
    <AbsoluteLiveIndicator /> :
    <RelativeLiveIndicator />;

export default LiveIndicator;
