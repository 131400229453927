import { createContext, useContext, useEffect, useState } from 'react';
import { useCanvas } from 'bloc/canvas/CanvasBloc';
import type { Canvas } from '@shared/schema/src';
import type { CurrentContextProviderProps } from './CurrentContextProviderProps';
import type { WithId } from '@mindhiveoy/schema';

interface CurrentCanvasContextType {
  /**
   * The current canvas
   */
  canvas: WithId<Canvas> | undefined;
  /**
   * True, when the canvas is loading
   */
  loading: boolean;

  mode?: 'draft' | 'published';
}

const CurrentCanvasContext = createContext<CurrentCanvasContextType | null>(null);

export const CurrentCanvasProvider: React.FC<CurrentContextProviderProps> = ({
  children,
  pathParams,
}) => {
  const canvasBloc = useCanvas(pathParams);

  const [canvas, setCanvas,] = useState<WithId<Canvas>>();

  const [loading, setLoading,] = useState(true);

  useEffect(() => canvasBloc.subscribe(
    (canvas) => {
      setCanvas(canvas);
      setLoading(!canvas);
    },
    (error) => {
      console.error('Error getting canvas', error);
      setLoading(false);
    }
  ), [canvasBloc,]);

  return (
    <CurrentCanvasContext.Provider value={{
      canvas,
      loading,
    }}>
      {children}
    </CurrentCanvasContext.Provider>
  );
};

/**
 * Use the current canvas in the url path
 * @returns   The current canvas
 */
export const useCurrentCanvas = () => {
  const context = useContext(CurrentCanvasContext);
  if (!context) {
    throw new Error('useCurrentCanvas must be used within a CurrentCanvasProvider');
  }
  return context;
};
