import { createContext, useContext, useEffect, useState } from 'react';
import { useProject } from 'bloc/project/ProjectBloc';
import type { CurrentContextProviderProps } from './CurrentContextProviderProps';
import type { Project } from '@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

interface CurrentProjectContextType {
  /**
   * The current project
   */
  project: WithId<Project> | undefined;
  /**
   * True, when the project is loading
   */
  loading: boolean;
}

const CurrentProjectContext = createContext<CurrentProjectContextType | null>(null);

export const CurrentProjectProvider: React.FC<CurrentContextProviderProps> = ({
  children,
  pathParams,
}) => {
  const projectBloc = useProject(pathParams);

  const [project, setProject,] = useState<WithId<Project>>();

  const [loading, setLoading,] = useState(true);

  useEffect(() => projectBloc.subscribe(
    (project) => {
      setProject(project);
      setLoading(!project);
    },
    (error) => {
      console.error('Error getting project', error);
      setLoading(false);
    }
  ), [projectBloc,]);

  return (
    <CurrentProjectContext.Provider value={{
      project,
      loading,
    }}>
      {children}
    </CurrentProjectContext.Provider>
  );
};

/**
 * Use the current project in the url path
 * @returns   The current project
 */
export const useCurrentProject = () => {
  const context = useContext(CurrentProjectContext);
  if (!context) {
    throw new Error('useCurrentProject must be used within a CurrentProjectProvider');
  }
  return context;
};
