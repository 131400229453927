import styled from '@emotion/styled';
import type { PropsWithChildren } from 'react';

const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Container = styled.div(({
  theme,
}) => `
  margin-top: ${theme.spacing(1)};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`);

const ContentBlock = ({
  children,
}: PropsWithChildren<unknown>) => {
  return <Container>
    <Block>{ children }</Block>
  </Container>;
};

export default ContentBlock;
