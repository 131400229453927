import styled from '@emotion/styled';

const BackgroundFade = styled.div(({
  theme,
}) => `
  margin: 0;
  background: rgb(2,0,36);
  background: linear-gradient(
    180deg,
    rgba(255,255,255,0) 0%,
    rgba(2,0,36,0) 50%,
    rgba(5,5,25, 0.3) 60%,
    ${BACKGROUND_FADE_DARK} 100%
  );
  pointer-events: none;
  display: flex;
  height: 100%;
  width: 100%;
  padding: ${theme.spacing(2, 2, 0, 2)};
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
`);

export const BackgroundFadeSteep = styled.div(({
  theme,
}) => `
  margin: 0;
  background: rgb(2,0,36);
  background: linear-gradient(
    180deg,
    rgba(255,255,255,0) 0%,
    rgba(2,0,36, 0) 20%,
    rgba(5,5,25, 0.3) 40%,
    ${BACKGROUND_FADE_DARK} 100%
  );
  pointer-events: none;
  display: flex;
  height: 100%;
  width: 100%;
  padding: ${theme.spacing(2, 2, 0, 2)};
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
`);

export const BACKGROUND_FADE_DARK = 'rgba(5,5,25,0.95)';

export const DarkBackgroundFade = styled.div`
  background-color: ${BACKGROUND_FADE_DARK};
`;

export default BackgroundFade;
