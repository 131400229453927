import { CurrentCanvasProvider } from './useCurrentCanvas';
import { CurrentProjectProvider } from './useCurrentProject';
import { CurrentSessionProvider } from './useCurrentSession';
import { CurrentSpaceProvider } from './useCurrentSpace';
import usePathParams from 'utils/hooks/usePathParams';
import type { PropsWithChildren } from 'react';

interface PageContextProviderProps {
  /**
   * The path level. This is used to determine the context provider,
   * that should be used for the current page. Based on the path level
   * the context provider will load the key documents only once for the
   * whole page.
   *
   * @type {'space' | 'project' | 'session' | 'canvas'}
   */
  pathLevel: 'space' | 'project' | 'session' | 'canvas';
}

/**
 * Create the page context for the current url in a way that the
 * path params can be quaranteed to be in sync with the current url
 * and key documents needs to be loaded only once for the whole page.
 *
 * @param {PageContextProviderProps}  props  The props
 */
const PageContextProvider = ({
  children,
  pathLevel,
}: PropsWithChildren<PageContextProviderProps>) => {
  const pathParams = usePathParams();

  switch (pathLevel) {
    case 'space':
      return <CurrentSpaceProvider pathParams={pathParams}>
        {children}
      </CurrentSpaceProvider>;

    case 'project':
      return <CurrentSpaceProvider pathParams={pathParams}>
        <CurrentProjectProvider pathParams={pathParams}>
          {children}
        </CurrentProjectProvider>
      </CurrentSpaceProvider>;

    case 'session':
      return <CurrentSpaceProvider pathParams={pathParams}>
        <CurrentProjectProvider pathParams={pathParams}>
          <CurrentSessionProvider pathParams={pathParams}>
            {children}
          </CurrentSessionProvider>
        </CurrentProjectProvider>
      </CurrentSpaceProvider>;

    case 'canvas':
      return <CurrentSpaceProvider pathParams={pathParams}>
        <CurrentProjectProvider pathParams={pathParams}>
          <CurrentSessionProvider pathParams={pathParams}>
            <CurrentCanvasProvider pathParams={pathParams}>
              {children}
            </CurrentCanvasProvider>
          </CurrentSessionProvider>
        </CurrentProjectProvider>
      </CurrentSpaceProvider>;
    default:
      return <div>Invalid path level</div>;
  }
};

export default PageContextProvider;
